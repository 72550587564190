<template>
  <v-container>
     <div>
       <v-row class="mb-4" justify="space-between" align-content="center">
        <h1 class="text text-h4 titulo">Post Costeo</h1>
        <export-post-cost :filter="filter" :options="options" :disabled="loading" :search="search"/>
       </v-row>
      <v-row class="mb-4" >
        <v-col align-self="center">
          <v-text-field 
          hide-details 
          :disabled="loading" 
          dense 
          v-model='filter.created_after' 
          class="mr-4" 
          outlined 
          clearable 
          type="date" 
          label="Creada Desde:"
          @change="()=>{options.page=1;fetch()}"
          />
        </v-col>
        <v-col align-self="center">
          <v-text-field 
          hide-details 
          :disabled="loading" 
          dense v-model='filter.created_before' 
          clearable 
          outlined 
          type="date" 
          label="Creada Hasta:" 
          @change="()=>{options.page=1;fetch()}"
          />
        </v-col>
        <v-col align-self="center">
          <v-text-field
            v-model="search"
            label="Buscar"
            append-icon="fas fa-search"
            @keyup="searchTimeOut"
            :disabled="loading"
            hide-details
          />
        </v-col>
      </v-row>
      <v-data-table
        class="pa-4"
        :headers="headers"
        :items="workOrders"
        :options.sync="options"
        :server-items-length="totalWorkOrders"
        :search="search"
        sort-by="created_at"
        :sort-desc="false"
        :single-expand="true"
        :loading="loading"
        :footer-props="{
          showFirstLastPage: true,
          firstIcon: 'fas fa-angle-double-left',
          lastIcon: 'fas fa-angle-double-right',
          prevIcon: 'fas fa-angle-left',
          nextIcon: 'fas fa-angle-right',
          itemsPerPageOptions: [10, 20, 30, 40, 50,100],
        }"
        @update:options="fetch"
      >
        <template v-slot:top>
        <!-- <v-row justify="end">
          <v-col cols="6">
            <v-text-field
                v-model="search"
                label="Buscar"
                append-icon="fas fa-search"
                @keyup="searchTimeOut"
                :disabled="loading"
              ></v-text-field>
          </v-col>
        </v-row> -->
        </template>
        <template v-slot:item.id="{ item }">
          <show-work-order :work_order_id="item.id">{{item.id}}</show-work-order>
        </template>
        <template v-slot:item.created_at="{ item }">
          <div>{{item.created_at | moment("DD/MM/YYYY")}}</div>
          <div>{{item.completed_at|moment("DD/MM/YYYY") }}</div>
        </template>
        <template v-slot:item.sales_note__name="{item}">
          {{item.name}}
        </template>
        <template v-slot:item.sales_note__customer__name="{item}">
          {{item.customer_name}}
          <div class="caption">{{item.seller_full_name}}</div>
        </template>
        <template v-slot:item.completed_at="{ item }">
          {{item.completed_at | moment("DD/MM/YYYY")}}
        </template>
        <template v-slot:item.estimated_cost="{ item }">
           {{item.real_price.sales_cost | currency('$', 0)}} [{{item.estimated_margin| percent(1)}}]
        </template>
        <template v-slot:item.real_price="{ item }">
          {{item.real_price.total | currency('$', 0)}} [{{item.real_margin| percent(1)}}] 
        </template>
        <template v-slot:item.total_sale="{ item }">
          {{item.real_price.sales_total | currency('$', 0)}}
        </template>
        <template v-slot:item.progress="{ item }">
          <v-progress-linear
            v-model="item.progress"
            color="primary"
            height="25"
            >
            <template v-slot:default="{ value }">
              <strong>{{ Math.ceil(value) }}%</strong>
            </template>
          </v-progress-linear>
        </template>
        <template v-slot:item.estimated="{ item }">
          <div v-if="item.estimated!=0">
            {{1-item.estimated/item.total_sale}}
            {{item.estimated | moment("DD/MM/YYYY")}}
          </div>
          <div v-else class="text-center">
            <v-icon color="success">fas fa-check-circle</v-icon>
          </div>
        </template>
        <template v-slot:item.actions="{ item }">
          <v-btn dense x-small text @click="$router.push({name:'DetailedPostCost',params:{id:item.id}})"><v-icon small class="mr-2">fas fa-eye</v-icon></v-btn>
          <!-- <v-menu
            offset-y
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                v-bind="attrs"
                v-on="on"
                text
                x-small
                class="success--text"
              >
                Acciones <v-icon class="ml-2">fas fa-angle-down</v-icon> 
              </v-btn>
            </template>
            <v-list dense>
              <v-list-item>
                <v-btn dense small text @click="$router.push({name:'DetailedPostCost',params:{id:item.id}})"><v-icon class="mr-2">fas fa-fingerprint</v-icon>Detalles</v-btn>
              </v-list-item>
            </v-list>
          </v-menu> -->
        </template>
  </v-data-table>
  </div>
  <v-dialog  v-model='display' max-width="1200">
    <v-card class="pa-4" >
      <work-order-card :work_order='workOrderOnDisplay' @close="display=false"/> 
    </v-card>
  </v-dialog>
  </v-container>
</template>

<script>
import {fetchPostCostWorkOrders,} from '@/api/reports'
import {fetchSellers} from '@/api/auth'
import CustomerComplete2 from '@/components/customers/CustomerComplete2.vue'
import WorkOrderCard from '@/components/production/WorkOrderCard'
import {pdfWorkOrder} from '@/doc_generators/workOrder'
import ExportPostCost from '@/components/reports/ExportPostCost'

import notifications from '@/utils/notifications'
import NumberLabel from '@/components/utils/NumberLabel'
import NumberCard from '@/components/utils/NumberCard'
import moment from 'moment'

export default {
  name:'PostCostWorkOrders',
  components:{CustomerComplete2,WorkOrderCard,NumberLabel,ExportPostCost,NumberCard,},
  
  data() {
    return {
      loading:false,
      
      filter:{
        created_after:moment(new Date(new Date().getFullYear(), new Date().getMonth(), 1)).format('yyyy-MM-DD'),
        created_before:'',
        },
      workOrderOnDisplay:{},
      options:{},
      totalWorkOrders:0,
      display:false,
      search:'',
      workOrders:[],
      estimated_margin:'',
      real_margin:'',
      total_sales:'',
      total_estimated:'',
      total_real:'',
      sellers:[],
      sellersLoading:false,
      headers:[
          {text: 'OT',align: 'start',value: 'id',class: 'table-header2'},
          {text: 'Creada | Finalizada',align: 'start',value: 'created_at',class: 'table-header2'},
          {text: 'Proyecto',align: 'start',value: 'sales_note__name',class: 'table-header2'},
          {text: 'Ct',value:'sales_note',class: 'table-header2'},
          {text: 'Cliente | Vendedor',align: 'start',value: 'sales_note__customer__name',class: 'table-header2'},
          {text: 'Venta',align: 'start',value: 'total_sale',class: 'table-header2',sortable:false,align:'end'},
          {text: 'Teórico',align: 'start',value: 'estimated_cost',class: 'table-header2',sortable:false,align:'end'},
          {text: 'Real',align: 'start',value: 'real_price',class: 'table-header2',sortable:false,align:'end'},
          { text: 'Detalles', value: 'actions',class: 'table-header2' ,sortable:false},
          { text: '', value: 'data-table-expand',class: 'table-header2',sortable:false },
        ]
    }
  },
  methods:{
    async fetch(){
      this.loading=true
        //Arreglando variables de fecha en caso de ser null
     //tomando en cuenta fecha UTC porque la busqueda es en timestamp
     var created_before = ''
      var created_after = ''
      if(this.filter.created_after) created_after = moment(this.filter.created_after).utc().format('yyyy-MM-DD HH:mm')
      if(this.filter.created_before) created_before=moment(this.filter.created_before).utc().add(1439,'minutes').format('yyyy-MM-DD HH:mm')

      const {sortBy, sortDesc,page, itemsPerPage }=this.options
      const query=`ordering=${sortDesc[0]?sortBy[0]:'-'+sortBy[0]}&page=${page}&page_size=${itemsPerPage}&search=${this.search}&created_after=${created_after}&created_before=${created_before}&completed=true`
      await fetchPostCostWorkOrders(query)
      .then((data)=>{
        this.totalWorkOrders=data.count
        this.workOrders = data.results.map(item=>{
          const {sales_cost, sales_total, total} = item.real_price
          item.estimated_margin=sales_total!=0?(1-sales_cost/sales_total):0
          item.real_margin=total!=0?(1-total/sales_total):0

          return item
        })
        this.total_sales=data.total_sales
        this.total_real=data.total_real
        this.total_estimated=data.total_estimated
        this.estimated_margin=data.estimated_margin
        this.real_margin=data.real_margin 
      })
      .catch((err)=>notifications.showError('Error Cargando Ordenes de Trabajo' + err))
      //Calculo porcentaje de completado del producto
      this.loading=false
    },
    showWorkOrder(item){
      this.workOrderOnDisplay=item
      this.display=true
    },
    printWorkOrder(item){
      pdfWorkOrder(item,this.$store.getters.getUser)
    },
    update(item){
      this.$router.push({name:'WorkOrderUpdate',params:{workOrder:item}})
    },
    searchTimeOut() {  
      //Función para hacer búsqueda lazy
      if (this.timer) {
          clearTimeout(this.timer);
          this.timer = null;
      }
      this.timer = setTimeout(() => {
          this.options.page = 1
          this.fetch()
      }, 800);
    },
  },
  async mounted(){
    await this.fetch()
    this.sellersLoading=true
    this.sellers=await fetchSellers()
    this.sellersLoading=false
  },
  watch:{
  }
}
</script>

<style lang="scss">
  .row-style-completed{
    cursor: pointer;
  }
  .row-style-normal{
    cursor: pointer;
  }
</style>



