import {updateUserParameters,fetchUserParameters} from '@/api/parameters.js'
import notifications from'@/utils/notifications'

const parameters = {
    state:()=>({
        userParameters:null,
    }),
    mutations:{
        SET_USER_PARAMETERS:(state,data)=>{
            state.userParameters=data
        }
    },
    getters:{
        getUserParameters:(state)=>{
            return state.userParameters
        }
    },
    actions:{
        updateUserParameters:async (context,data)=>{
            await updateUserParameters(data)
            .then(data=>{
                
                context.commit('SET_USER_PARAMETERS',data)
            })
            .catch(e=>notifications.showError(e))
        },
        fetchUserParameters:async (context)=>{
            await fetchUserParameters()
            .then(data=>{
                
                context.commit('SET_USER_PARAMETERS',data)
            })
            .catch(e=>notifications.showError(e))
        }
    }
}

export default parameters